import React from 'react';
import './Footer.css'; // Assuming you have the CSS for the footer

function Footer() {
  return (
    <div className="footer">
      <div className="footer-content">
        <h3>Color Picker</h3>
        <p>Explore a variety of colors and view their HEX and RGB values with our interactive color picker. Ideal for web design and digital creativity.</p>
        <button className="cta-button">Get Started</button>
        <div className="footer-links">
          <ul>
            <li><a href="/about">About</a></li>
            <li><a href="/contact">Contact</a></li>
            <li><a href="/privacy">Privacy Policy</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;
