import React from 'react';
import './Htmlcolor.css';

const hexColors = [
  { name: 'Black', hex: '#000000' },
  { name: 'White', hex: '#FFFFFF' },
  { name: 'Red', hex: '#FF0000' },
  { name: 'Lime', hex: '#00FF00' },
  { name: 'Blue', hex: '#0000FF' },
  { name: 'Yellow', hex: '#FFFF00' },
  { name: 'Cyan', hex: '#00FFFF' },
  { name: 'Magenta', hex: '#FF00FF' },
  { name: 'Silver', hex: '#C0C0C0' },
  { name: 'Gray', hex: '#808080' },
  { name: 'Maroon', hex: '#800000' },
  { name: 'Olive', hex: '#808000' },
  { name: 'Green', hex: '#008000' },
  { name: 'Purple', hex: '#800080' },
  { name: 'Teal', hex: '#008080' },
  { name: 'Navy', hex: '#000080' },
  { name: 'Orange', hex: '#FFA500' },
  { name: 'Pink', hex: '#FFC0CB' },
  { name: 'Gold', hex: '#FFD700' },
  { name: 'Brown', hex: '#A52A2A' },
  { name: 'Chocolate', hex: '#D2691E' },
  { name: 'Coral', hex: '#FF7F50' },
  { name: 'Indigo', hex: '#4B0082' },
  { name: 'Turquoise', hex: '#40E0D0' },
  { name: 'Violet', hex: '#EE82EE' }
];

const rgbColors = [
  { name: 'Tomato', rgb: 'rgb(255, 99, 71)' },
  { name: 'Orange Red', rgb: 'rgb(255, 69, 0)' },
  { name: 'Dodger Blue', rgb: 'rgb(30, 144, 255)' },
  { name: 'Deep Sky Blue', rgb: 'rgb(0, 191, 255)' },
  { name: 'Light Sea Green', rgb: 'rgb(32, 178, 170)' },
  { name: 'Medium Sea Green', rgb: 'rgb(60, 179, 113)' },
  { name: 'Sea Green', rgb: 'rgb(46, 139, 87)' },
  { name: 'Dark Goldenrod', rgb: 'rgb(184, 134, 11)' },
  { name: 'Dark Orange', rgb: 'rgb(255, 140, 0)' },
  { name: 'Hot Pink', rgb: 'rgb(255, 105, 180)' },
  { name: 'Dark Violet', rgb: 'rgb(148, 0, 211)' },
  { name: 'Medium Purple', rgb: 'rgb(147, 112, 219)' },
  { name: 'Medium Orchid', rgb: 'rgb(186, 85, 211)' },
  { name: 'Dark Khaki', rgb: 'rgb(189, 183, 107)' },
  { name: 'Slate Gray', rgb: 'rgb(112, 128, 144)' }
];

function Htmlcolor() {
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert(`Color code ${text} copied to clipboard!`);
      })
      .catch((err) => {
        console.error('Failed to copy color code:', err);
      });
  };

  return (
    <div className="htmlcolor-container">
      <h2>HTML Color List (Hex Codes)</h2>
      <div className="color-grid">
        {hexColors.map((color, index) => (
          <div
            key={index}
            className="color-card"
            style={{ backgroundColor: color.hex }}
            onClick={() => handleCopy(color.hex)}
          >
            <p className="color-name">{color.name}</p>
            <p className="color-hex">{color.hex}</p>
          </div>
        ))}
      </div>

      <h2>HTML Color List (RGB Codes)</h2>
      <div className="color-grid">
        {rgbColors.map((color, index) => (
          <div
            key={index}
            className="color-card"
            style={{ backgroundColor: color.rgb }}
            onClick={() => handleCopy(color.rgb)}
          >
            <p className="color-name">{color.name}</p>
            <p className="color-hex">{color.rgb}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Htmlcolor;
