import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import ColorPicker from './ColorPicker';
import ColorDisplay from './ColorDisplay';
import Navbar from './compo/Navbar/Navbar';
import './App.css';

const Home = () => {
  const [selectedColor, setSelectedColor] = useState('#000000');

  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  // Calculate HEX and RGB values from selectedColor
  const hex = selectedColor.substring(1); // Remove '#' from selectedColor
  const rgb = {
    r: parseInt(selectedColor.substring(1, 3), 16),
    g: parseInt(selectedColor.substring(3, 5), 16),
    b: parseInt(selectedColor.substring(5, 7), 16),
  };

  return (
    <>
      <Helmet>
        <title>Color Picker - Choose Your Favorite Color</title>
        <meta name="description" content="Use our interactive color picker to select colors and view their HEX and RGB values. Perfect for web design and digital projects!" />
        <meta name="keywords" content="color picker, hex color, rgb color, web design, color tools, interactive color picker, design tools" />
        <meta name="author" content="Your Name" />
        <meta property="og:title" content="Color Picker - Choose Your Favorite Color" />
        <meta property="og:description" content="Explore a variety of colors and their HEX and RGB values with our color picker tool. Ideal for web design and digital creativity." />
        <meta property="og:image" content="path_to_image_for_og_image" />
        <meta property="og:url" content="yourwebsiteurl.com/color-picker" />
        <link rel="canonical" href="yourwebsiteurl.com/color-picker" />
      </Helmet>

      <div className="home-container">
        {/* <Navbar /> */}
        <div className="content-container">
          <div className="color-panel">
            <div className="color-info">
              <div
                className="color-preview"
                style={{ backgroundColor: selectedColor }}
              ></div>
              <h4>HEX: <span>#{hex}</span></h4>
              <h4>RGB: <span>{`${rgb.r}, ${rgb.g}, ${rgb.b}`}</span></h4>
            </div>
            <div className="picker-display">
              <ColorPicker onSelectColor={handleColorSelect} />
              <ColorDisplay color={selectedColor} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
