import React, { useState } from 'react'
import './Login.css'
import { Link } from 'react-router-dom'
import { error } from 'jodit/esm/core/helpers';

function Login() {
  const [login , setLogin] = useState({username:'',password:''});

  const handelUser = (e) =>{
    const {value} = e.terget;
    setLogin({...Login ,username:value})
  }
  const handelPassword = (e) =>{
    const {value} = e.target;
    setLogin({ ...Login, password:value})
  }
   const handelSubmit = async (e)=>{
    e.preventDefault();
    try{
      const repo = await fetch('',{
        method:'POST',
        headers:{
          'Content-Type':'application/json'
        },
        body: JSON.stringify(Credential)
      });
      if(repo.ok){
        alert("login done")
      }
      else{
        alert("login fail;ed")
      }
    }
    catch(error){
        
    }
   }

  return (
    <div className='main_section'>
      <div className='login_card'>
        <form>
        <label>username</label>
        <input placeholder='Enter a username'></input>
        <label>password</label>
        <input placeholder='Enter password'></input>
        <button type='submit' ></button>
        </form>
        <div>
            <p><Link to="/sign">Sign</Link></p>
        </div>
      </div>
    </div>
  )
}

export default Login
