import React, { useState } from 'react'

function Sign() {
  const [registerData, setRegisterData] = useState({
    name: '',
    number: '',
    username: '',
    password: ''
  });

  const handleName = (e) => {
    const { value } = e.target;
    setRegisterData({ ...registerData, name: value });
};
const handleNumber = (e) => {
  const { value } = e.target;
  setRegisterData({ ...registerData, number: value });
};
const handleUser = (e) => {
  const { value } = e.target;
  setRegisterData({ ...registerData, username: value });
};
const handlePassword = (e) => {
  const { value } = e.target;
  setRegisterData({ ...registerData, password: value });
};
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:8080/User/reg', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(registerData)
      });
      // Handle response as needed
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div style={{marginTop:'10%'}}>
      <form  onSubmit={handleSubmit}>
        <label>Name</label>
        <input placeholder='enter name'
         value={registerData.name}
         type='text'
         name='name'
         onChange={handleName}
        ></input>
        <label>number</label>
        <input type='text' placeholder='Enter number' value={registerData.number} name='number' onChange={handleNumber}></input>
        <label>User name</label>
        <input type='text' placeholder='Enter User name' value={registerData.username} name='username' onChange={handleUser}></input>
        <label>password</label>
        <input type='password' placeholder='Enter password' value={registerData.password} name='password' onChange={handlePassword}></input>
        <button type='submit'>create user</button>
      </form>
    </div>
  )
}

export default Sign
