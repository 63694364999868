import React, { useState } from 'react';
import './HEX.css';

function HEX() {
  const [hex, setHex] = useState('');
  const [rgb, setRGB] = useState({ r: 0, g: 0, b: 0 });
  const [color, setColor] = useState('#000000');

  const handleInputChange = (event) => {
    const { value } = event.target;
    setHex(value);
    if (value.length === 6) {
      const r = parseInt(value.slice(0, 2), 16);
      const g = parseInt(value.slice(2, 4), 16);
      const b = parseInt(value.slice(4, 6), 16);
      setRGB({ r, g, b });
      setColor(`#${value.toUpperCase()}`);
    } else {
      setRGB({ r: 0, g: 0, b: 0 });
      setColor('#000000');
    }
  };

  const convertToRGB = () => {
    if (hex.length === 6) {
      const r = parseInt(hex.slice(0, 2), 16);
      const g = parseInt(hex.slice(2, 4), 16);
      const b = parseInt(hex.slice(4, 6), 16);
      setRGB({ r, g, b });
      setColor(`#${hex.toUpperCase()}`);
    }
  };

  return (
    <div className="hex-container">
      <h2>HEX to RGB Converter</h2>
      <div className="input-group">
        <label htmlFor="hex">HEX:</label>
        <input type="text" id="hex" value={hex} onChange={handleInputChange} placeholder="Enter HEX code" />
      </div>
      <div className="rgb-output">
        <label>RGB:</label>
        <span>{`(${rgb.r}, ${rgb.g}, ${rgb.b})`}</span>
      </div>
      <button className="convert-button" onClick={convertToRGB}>Convert</button>
      <div className="color-display" style={{ backgroundColor: color }}></div>
    </div>
  );
}

export default HEX;
