import React, { useState, useEffect } from 'react';
import UpdateModal from './UpdateModal';

import './Allpost.css';

function AllPost() {
  const [posts, setPosts] = useState([]);
  const [updateData, setUpdateData] = useState({ id: null, tittle: '', about: '' });
  const [editPostId, setEditPostId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const response = await fetch('http://localhost:8080/api/gett');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setPosts(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`http://localhost:8080/api/delete/${id}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        console.log("Error deleting post");
      } else {
        setPosts(posts.filter(post => post.id !== id));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateClick = (id) => {
    setEditPostId(id);
    const postToUpdate = posts.find(post => post.id === id);
    setUpdateData({ id: postToUpdate.id, tittle: postToUpdate.tittle, about: postToUpdate.about });
    setIsModalOpen(true);
  };

  const handleUpdate = async () => {
    try {
      const response = await fetch(`http://localhost:8080/api/update/${updateData.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updateData)
      });
      if (!response.ok) {
        console.log("Error updating post");
      } else {
        // Refresh posts after update
        fetchPosts();
        // Clear updateData state
        setUpdateData({ id: null, tittle: '', about: '' });
        setEditPostId(null);
        setIsModalOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div style={{ marginTop: '10%' }}>
        <table className='main_section'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>About</th>
              <th>Delete</th>
              <th>Update</th>
            </tr>
          </thead>
          <tbody>
            {posts.map(post => (
              <tr key={post.id}>
                <td>{post.id}</td>
                <td>{post.tittle}</td>
                <td>{post.about}</td>
                <td><button onClick={() => handleDelete(post.id)}>Delete</button></td>
                <td>
                  <button onClick={() => handleUpdateClick(post.id)}>Update</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <UpdateModal 
        isOpen={isModalOpen} 
        onClose={handleCloseModal} 
        onUpdate={handleUpdate} 
        updateData={updateData} 
        setUpdateData={setUpdateData} 
      />
    </div>
  );
}

export default AllPost;
