import React from 'react'
import Home from './Home'
import { Route } from 'react-router'
import Routing from './Routing'

function App() {
  return (
    <>
    <Routing/>
    </>
  );
}

export default App