import React, { useState } from 'react';
import './Navbar.css';
import { Link } from "react-router-dom";
import logo from '../../img/color.png'

const Navbar = () => {
  // State to toggle the side menu visibility
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle menu visibility
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <>
      <nav>
        {/* Logo */}
        <div className="logo">
         <div className='logo_de'>
         <img src={logo} alt="Logo" className="logo-img" />
         <p>Color Pick Color</p>
         </div>

        </div>

        {/* Hamburger Menu Button (Visible only on mobile) */}
        <div className="hamburger" onClick={toggleMenu}>
          ☰
        </div>

        {/* Side Menu (Only visible on mobile when isMenuOpen is true) */}
        <ul className={`nav-links ${isMenuOpen ? 'show' : ''}`}>
          <li><Link to="/" className="nav-item">Color Picker</Link></li>
          <li><Link to="/rgb" className="nav-item">RGB to HEX</Link></li>
          <li><Link to="/hex" className="nav-item">HEX to RGB</Link></li>
          <li><Link to="/color" className="nav-item">HTML & CSS COLOR</Link></li>
          {/* <li><Link to="/css" className="nav-item">CSS COLOR</Link></li> */}

          <li><Link to="/cpage" className="nav-item">CONTACT US</Link></li>
          <li><Link to="/apage" className="nav-item">ABOUT</Link></li>

          <li><Link to="/ppage" className="nav-item">PRIVACY POLICY</Link></li>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
