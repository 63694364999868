import React, { useRef, useState } from 'react';
import JoditEditor from 'jodit-react';

const PostAdmin = () => {
    const [postData, setPostData] = useState({ about: '', tittle: '' });
    const editor = useRef(null);

    const handleChange = (content) => {
        console.log(content)
        setPostData({ ...postData, about: content });
    };

    const handleTitleChange = (e) => {
        const { value } = e.target;
        setPostData({ ...postData, tittle: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('http://localhost:8080/api/post', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            alert('Post created successfully');
            // Reset form data after successful submission
            setPostData({ about: '', tittle: '' });
        } catch (error) {
            console.error('Error:', error);
            alert('Error creating post');
        }
    };

    return (
        <div style={{marginTop:'20%', display:'flex', justifyContent:'center'}}>
            <div>
                <form onSubmit={handleSubmit}>
                    
                    <div>
                        <label htmlFor="title">Title:</label>
                        <input
                            type="text"
                            id="tittle"
                            name="tittle"
                            value={postData.tittle}
                            onChange={handleTitleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="content">Content:</label>
                        <JoditEditor
                            ref={editor}
                            value={postData.about}
                            onChange={handleChange}
                        />
                    </div>
                    <button type="submit">Create Post</button>
                </form>
            </div>
        </div>
    );
};

export default PostAdmin;
