import React from 'react';
import './ColorChart.css'; // You can define styles for this component if needed.

const colorChart = [
  { name: 'AliceBlue', hex: '#F0F8FF', rgb: 'rgb(240, 248, 255)' },
  { name: 'AntiqueWhite', hex: '#FAEBD7', rgb: 'rgb(250, 235, 215)' },
  { name: 'Aqua', hex: '#00FFFF', rgb: 'rgb(0, 255, 255)' },
  { name: 'Aquamarine', hex: '#7FFFD4', rgb: 'rgb(127, 255, 212)' },
  { name: 'Azure', hex: '#F0FFFF', rgb: 'rgb(240, 255, 255)' },
  { name: 'Beige', hex: '#F5F5DC', rgb: 'rgb(245, 245, 220)' },
  { name: 'Black', hex: '#000000', rgb: 'rgb(0, 0, 0)' },
  { name: 'BlanchedAlmond', hex: '#FFEBCD', rgb: 'rgb(255, 235, 205)' },
  { name: 'Blue', hex: '#0000FF', rgb: 'rgb(0, 0, 255)' },
  { name: 'Brown', hex: '#A52A2A', rgb: 'rgb(165, 42, 42)' },
  { name: 'CadetBlue', hex: '#5F9EA0', rgb: 'rgb(95, 158, 160)' },
  { name: 'Chartreuse', hex: '#7FFF00', rgb: 'rgb(127, 255, 0)' },
  { name: 'Chocolate', hex: '#D2691E', rgb: 'rgb(210, 105, 30)' },
  { name: 'Coral', hex: '#FF7F50', rgb: 'rgb(255, 127, 80)' },
  { name: 'Crimson', hex: '#DC143C', rgb: 'rgb(220, 20, 60)' },
  { name: 'Cyan', hex: '#00FFFF', rgb: 'rgb(0, 255, 255)' },
  { name: 'DarkBlue', hex: '#00008B', rgb: 'rgb(0, 0, 139)' },
  { name: 'DarkGreen', hex: '#006400', rgb: 'rgb(0, 100, 0)' },
  { name: 'DeepPink', hex: '#FF1493', rgb: 'rgb(255, 20, 147)' },
  { name: 'DeepSkyBlue', hex: '#00BFFF', rgb: 'rgb(0, 191, 255)' },
  { name: 'DimGray', hex: '#696969', rgb: 'rgb(105, 105, 105)' },
  { name: 'DodgerBlue', hex: '#1E90FF', rgb: 'rgb(30, 144, 255)' },
];

function ColorChart() {
  const handleCopy = (value) => {
    navigator.clipboard.writeText(value)
      .then(() => {
        alert(`${value} copied to clipboard!`);
      })
      .catch((err) => {
        console.error('Failed to copy color code:', err);
      });
  };

  return (
    <div className="colorchart-container">
      <h2>Color Chart</h2>
      <div className="color-grid">
        {colorChart.map((color, index) => (
          <div
            key={index}
            className="color-card"
            style={{ backgroundColor: color.hex }}
            onClick={() => handleCopy(color.hex)}
          >
            <p className="color-name">{color.name}</p>
            <p className="color-hex">{color.hex}</p>
            <p className="color-rgb">{color.rgb}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ColorChart;
