import React from 'react';
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Navbar from './compo/Navbar/Navbar';
import HEX from './compo/HEX/HEX';
import RGB from './compo/rgbandhex/RGB';
import Post from './compo/post/Post';
import Admin from './compo/admin/Admin';
import Des from './compo/post/Des';
import PostAdmin from './compo/admin/PostAdmin';
import AllPost from './compo/admin/AllPost';
import Login from './compo/Login/Login';
import Sign from './compo/Sign/Sign';
import Htmlcolor from './compo/HtmlcolorList/Htmlcolor';
import CssColor from './compo/Csscolorlist/CssColor';
import ColorChart from './compo/Chart/ColorChart';
import Footer from './compo/Footer/Footer';
import AboutPage from './compo/AboutPage/AboutPage';
import ContactPage from './compo/ContactPage/ContactPage';
import PrivacyPolicyPage from './compo/PrivacyPolicyPage/PrivacyPolicyPage';



function Routing() {
  return (
    <>
    <HashRouter>
      {/* <BrowserRouter> */}
      <Navbar/>
        <Routes>
          <Route path="/" element={<Home />}/>
            <Route index element={<Home />} />
            <Route path="/rgb" element={<RGB />} />
            <Route path="/hex" element={<HEX />} />
            <Route path="/post" element={<Post/>} />
            <Route path="/admin" element={<Admin/>} />
            <Route path="/post/:id" element={<Des/>} />
            <Route path="/postAdmin" element={<PostAdmin/>} />
            <Route path="/allpost" element={<AllPost/>} />
            <Route path="/login" element={<Login/>} />
            <Route path="/sign" element={<Sign/>} />
            <Route path="/color" element={<Htmlcolor/>} />
            <Route path="/css" element={<CssColor/>} />
            <Route path="/chart" element={<ColorChart/>} />
            <Route path="/chart" element={<Footer/>} />

            <Route path="/apage" element={<AboutPage/>} />
            <Route path="/cpage" element={<ContactPage/>} />
            <Route path="/ppage" element={<PrivacyPolicyPage/>} />
        </Routes>
        <Footer/>
      {/* </BrowserRouter> */}
      </HashRouter>
    </>
  );
}

export default Routing;
