import React from 'react';

const PrivacyPolicyPage = () => {
  return (
    <div className="page-content">
      <h1>Privacy Policy</h1>
      <p>Your privacy is very important to us. This Privacy Policy document outlines the types of personal information that is received and collected by Color Picker and how it is used.</p>
      <p>We do not sell, trade, or rent users' personal information to others.</p>
      <p>For more information, please feel free to contact us at any time.</p>
    </div>
  );
};

export default PrivacyPolicyPage;
