import React, { useState } from 'react';

function RGB() {
  const [rgb, setRGB] = useState({ r: 0, g: 0, b: 0 });
  const [hex, setHex] = useState('');
  const [color, setColor] = useState('#000000');

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setRGB({ ...rgb, [name]: parseInt(value) || 0 });
  };

  const convertToHex = () => {
    const { r, g, b } = rgb;
    const hexCode = ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
    setHex(hexCode);
    setColor(`#${hexCode}`);
  };

  return (
    <div style={{ paddingTop: '10%', backgroundColor: '#EAEAEA', display: 'flex', justifyContent: 'center' }}>
      <div style={{ backgroundColor: '#ffff',  }}>
       
       <div style={{display:'flex', alignItems:'center',gap:'10%', alignSelf:'center',justifyContent:'center', borderBottom: '0.1px solid black', borderWidth:'2%'}}>
       <div style={{  width: '70px', height: '30px', backgroundColor: color }}></div>

        <h4 style={{display:'flex',gap:10,}}>HEX: <span>#{hex}</span></h4>
        <h4 style={{display:'flex',gap:10,}}>RGB: <span style={{display:'flex'}}>{`${rgb.r}, ${rgb.g}, ${rgb.b}`}</span> </h4>
       </div>
    


      <div style={{padding:"10%"}}>
       <div style={{display:'flex', gap:20}}>
       <div>
          <label style={{color:"red", fontWeight:'600'}} >Red:</label>
          <input style={{width:'200px', height:'27px', borderRadius:'4px',borderColor:"red", borderWidth:'1px'}} type="text" id="red" name="r" value={rgb.r} onChange={handleInputChange} />
        </div>
        <div>
          <label style={{color:"green", fontWeight:'600'}}>Green:</label>
          <input style={{width:'200px', height:'27px', borderRadius:'4px',borderColor:"green", borderWidth:'1px'}} type="text" id="green" name="g" value={rgb.g} onChange={handleInputChange} />
        </div>
        <div>
          <label style={{color:"blue", fontWeight:'600'}}>Blue:</label>
          <input style={{width:'200px', height:'27px', borderRadius:'4px',borderColor:"blue", borderWidth:'1px'}} type="text" id="blue" name="b" value={rgb.b} onChange={handleInputChange} />
        </div>
       </div>
       <div style={{display:'flex', justifyContent:'center',marginTop:'6%',}}>
       <button style={{width:'34%', height:'33px',borderRadius:'5px',borderWidth:'1px',backgroundColor:'#b900e7', color:'#fff', fontWeight:'700'}} onClick={convertToHex}>Convert</button>
       </div>
        <div>
          <label>Hex Code:</label>
          <span >{hex}</span>
        </div>
        <div style={{ marginTop: '10px', width: '650px', height: '100px', backgroundColor: color }}></div>
       
      </div>
      </div>
    </div>
  );
}

export default RGB;
