import React from 'react';

const AboutPage = () => {
  return (
    <div className="page-content">
      <h1>About Us</h1>
      <p>Welcome to Color Picker, a tool to help you choose and convert colors easily!</p>
      <p>Here, you can pick colors using various formats like RGB, HEX, and more. Use our tool for color selection, color analysis, and design inspiration.</p>
    </div>
  );
};

export default AboutPage;
