import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function Des() {
  const { id } = useParams(); // Corrected to use id instead of postId
  const [post, setPost] = useState(null);

  useEffect(() => {
    async function fetchPost() {
      try {
        const response = await fetch(`http://localhost:8080/api/gett/${id}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const postData = await response.json();
        setPost(postData);
      } catch (error) {
        console.error('Error:', error);
        // Handle error here
      }
    }

    fetchPost();
  }, [id]);

  if (!post) {
    return <div>Loading...</div>;
  }

  const { tittle, about } = post; 

  return (
    <div>
      <h2>{tittle}</h2>
      <div dangerouslySetInnerHTML={{ __html: about }} />
    </div>
  );
}

export default Des;
