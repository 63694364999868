import React from 'react';
import 'jodit';

import JoditEditor from 'jodit-react';
import './UpdateModal.css'

function UpdateModal({ isOpen, onClose, onUpdate, updateData, setUpdateData }) {
  const handleChange = (content) => {
    // JoditEditor returns content, not event, so we directly set the content to updateData.about
    setUpdateData({ ...updateData, about: content });
  };

  return (
    <>
      {isOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={onClose}>&times;</span>
            <h2>Update Post</h2>
            <input type="text" name="tittle" value={updateData.tittle} onChange={(e) => setUpdateData({ ...updateData, tittle: e.target.value })} />
            <JoditEditor
              value={updateData.about}
              onChange={handleChange}
              tabIndex={1} // tabIndex of textarea
              config={{
                zIndex: 0, // To make it visible in the modal
              }}
            />
            <button onClick={onUpdate}>Save</button>
          </div>
        </div>
      )}
    </>
  );
}

export default UpdateModal;
